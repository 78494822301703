html,
body {
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: "Open Sans";
  overflow: hidden;
  font-size: 14px;
}

@media screen and (max-width: 1280px) {
  html,
  body {
    font-size: 0.8em;
  }
}

a {
  outline: none;
}

h4 {
  margin-bottom: 0;
}

body,
.search-contents {
  background-image: url(./images/bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#gaas-content,
#root {
  height: 100%;
}

#busy-indicator {
  width: 292px;
  height: 292px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -146px;
  margin-top: -186px;
  z-index: 9999;
  display: none;
}

#gaas-sidebar {
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

#gaas-sidebar hr {
  margin: 0 10px;
  border-color: rgba(182, 191, 195, 0.1);
}

#gaas-sidebar-user {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgba(182, 191, 195, 0.1);
}

#gaas-sidebar-user img {
  width: 60px;
}

@media screen and (max-height: 800px) {
  #gaas-sidebar-user {
    display: none;
  }
}

#gaas-sidebar-user > div {
  font-size: 1.2em;
  display: inline-block;
  margin-left: 10px;
  color: rgba(182, 191, 195, 0.8);
}

#gaas-sidebar-user > * {
  vertical-align: middle;
}

#gaas-sidebar-user .link {
  cursor: pointer;
}

#gaas-sidebar-user .link:hover {
  color: #3db77a !important;
  text-decoration: underline;
}

#gaas-sidebar-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 20px;
}

#gaas-sidebar-menu li {
  margin: 0;
  padding: 5px 30px 2px 30px;
  color: rgba(182, 191, 195, 0.4);
  font-size: 1.4em;
  list-style: none;
  cursor: pointer;
}

#gaas-sidebar-menu li.sublink {
  font-size: 1em;
  padding: 2px 30px 2px 30px;
  padding-left: 60px;
}

@media screen and (max-height: 800px) {
  #gaas-sidebar-menu li {
    padding: 1px 20px;
  }
  #gaas-sidebar-menu li.sublink {
    padding: 1px 20px;
    padding-left: 30px;
  }
}

#gaas-sidebar-menu li.sub-menu {
  margin: 0;
  padding: 15px 30px;
  color: rgba(182, 191, 195, 0.4);
  font-size: 1.1em;
  list-style: none;
  cursor: pointer;
}

#gaas-sidebar-menu li a {
  color: rgba(182, 191, 195, 0.5);
}

#gaas-sidebar-menu li.active a,
#gaas-sidebar-user .link.active {
  color: #3db77a;
}

@media screen and (max-width: 1280px) {
  #gaas-sidebar-menu .menu-icon {
    display: none;
  }
}

.gaas-sidebar-logo {
  position: relative;
}

.gaas-sidebar-logo > img {
  width: 100%;
  margin-top: 20px;
}

#gaas-sidebar .react-select-container {
  margin-left: 10px !important;
}

#gaas-sidebar .react-select__placeholder,
#gaas-sidebar .react-select__single-value,
#gaas-sidebar .react-select__indicator,
#gaas-sidebar .react-select__input {
  color: rgba(182, 191, 195, 0.6) !important;
}

.react-select__indicator-separator {
  background-color: rgba(182, 191, 195, 0.6) !important;
}

#content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  /*border: 1px solid blue;*/
  /*overflow-y: hidden;
    overflow-x: hidden;*/
  color: white;
}

@media all and (max-width: 1440px) {
  #content {
    padding: 10px 0;
  }
}

div.a {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  background: url(./images/triangle.svg);
  z-index: -1;
}

div.b {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 35px;
  height: 35px;
  background: url(./images/triangle.svg);
  z-index: -1;
}

div.c {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 25px;
  height: 25px;
  background: url(./images/triangle.svg);
  z-index: -1;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating-c {
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
}

.rotating-b {
  -webkit-animation: rotating 14s linear infinite;
  -moz-animation: rotating 14s linear infinite;
  -ms-animation: rotating 14s linear infinite;
  -o-animation: rotating 14s linear infinite;
  animation: rotating 14s linear infinite;
}

.rotating-a {
  -webkit-animation: rotating 18s linear infinite;
  -moz-animation: rotating 18s linear infinite;
  -ms-animation: rotating 18s linear infinite;
  -o-animation: rotating 18s linear infinite;
  animation: rotating 18s linear infinite;
}

#login-box {
  height: 550px;
  width: 550px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -275px;
  margin-left: -275px;
  background: url(./images/login.svg);
  z-index: 999;
}

@media all and (max-height: 800px) {
  #login-box {
    height: 350px;
    width: 350px;
    margin-top: -175px;
    margin-left: -175px;
  }
}

#login-box input {
  background: none;
  border: none;
  border-bottom: 1px solid rgba(38, 38, 38, 0.45);
  color: white;
  line-height: 34px;
  font-size: 1.4em;
  float: left;
  width: 85%;
}

@media all and (max-height: 800px) {
  #login-box input {
    line-height: 30px;
  }
}

#login-box input:focus {
  outline: none;
  border-bottom: 2px solid rgba(61, 183, 122, 0.75);
}

#login-box .logo {
  position: absolute;
  width: 320px;
  top: 125px;
  left: 50%;
  margin-left: -160px;
}

@media all and (max-height: 800px) {
  #login-box .logo {
    width: 215px;
    top: 50px;
    left: 65%;
  }
}

#login-box .username {
  position: absolute;
  top: 280px;
  left: 25%;
  width: 50%;
}

@media all and (max-height: 800px) {
  #login-box .username {
    width: 60%;
    top: 180px;
  }
}

#login-box .username > img,
#login-box .password > img {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}

@media all and (max-height: 800px) {
  #login-box .username > img,
  #login-box .password > img {
    display: none;
  }
}

#login-box .password {
  position: absolute;
  top: 340px;
  left: 25%;
  width: 50%;
}

@media all and (max-height: 800px) {
  #login-box .password {
    width: 60%;
    top: 220px;
  }
}

#login-box #button {
  cursor: pointer;
}

#login-box input:focus::-webkit-input-placeholder {
  color: rgba(61, 183, 122, 0.75) !important;
}

#login-box input:focus::-moz-placeholder {
  color: rgba(61, 183, 122, 0.75) !important;
}

#login-box input:focus:-moz-placeholder {
  color: rgba(61, 183, 122, 0.75) !important;
}

#login-box input:focus:-ms-input-placeholder {
  color: rgba(61, 183, 122, 0.75) !important;
}

#login-box .username > img:focus {
  color: rgba(61, 183, 122, 0.75) !important;
}

label {
  font-size: 1.1em;
}

.title {
  font-size: 2.8em !important;
  padding-left: 0px !important;
  height: 0px !important;
}

textarea {
  margin-top: 10px;
  /*margin-left: 12px !important;*/
  width: 500px;
  /* height: 45px !important; */
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
  border-color: -moz-use-text-color #ffffff #ffffff -moz-use-text-color;
  border-image: none;
  border-radius: 6px 6px 6px 6px;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
  color: white !important;
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  font-size: 1em;
  line-height: 1.4em;
  padding: 5px 2px !important;
  transition: background-color 0.2s ease 0s;
  padding-right: 10px !important;
}

textarea:focus {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.3);
  outline-width: 0;
  color: white;
}

.octomask {
  position: absolute;
  top: -35px;
  left: 0;
  z-index: 1;
}

.octomask img {
  border: 1px solid lightgray;
}

small,
.small {
  color: white !important;
}

.form-control:first-child {
  padding-left: 0;
}

.list .element-item {
  padding-left: 25px !important;
}

text {
  fill: #fff !important;
}

.custom-GaaS-hr {
  background-color: rgba(61, 183, 122, 0.9) !important;
  margin-top: -4px;
  padding: 1px 5px;
}

.stars {
  height: 36px;
  width: 36px;
  z-index: 1;
  /* margin-left: -32px; */
  /* margin-top: -3px; */
  position: absolute;
  left: 42px;
  top: 5px;
}

pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  color: #333;
  background-color: rgba(254, 245, 245, 0.4);
}

.string {
  color: green;
}

.number {
  color: darkorange;
}

.boolean {
  color: blue;
}

.null {
  color: magenta;
}

.key {
  color: red;
}

.table-image {
  height: 32px;
  width: 32px;
}

.avatar {
  float: left;
  height: 40px;
  width: 40px;
  position: relative;
  object-fit: cover;
  /* -webkit-border-radius: 50%; */
  /* -moz-border-radius: 50%; */
  /* border-radius: 50%; */
  -webkit-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
}
.avatar.xl {
  height: 150px;
  width: 150px;
  background-color: white;
}
.avatar.lg {
  height: 80px;
  width: 80px;
  background-color: white;
}
.avatar.sm {
  height: 50px;
  width: 50px;
  background-color: white;
}
.avatar.xs {
  height: 24px;
  width: 24px;
  background-color: white;
}
.avatar.disabled {
  /* -webkit-border-radius: 50%; */
  /* -moz-border-radius: 50%; */
  /* border-radius: 50%; */
  -webkit-box-shadow: 0 0 0 3px rgba(178, 5, 5, 0.3), 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0 0 0 3px rgba(178, 5, 5, 0.3), 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 3px rgba(178, 5, 5, 0.3), 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 1280px) {
  .table-image {
    height: 24px;
    width: 24px;
  }
  .avatar.xl {
    height: 120px;
    width: 120px;
  }
  .avatar.lg {
    height: 60px;
    width: 60px;
  }
}
.react-select__control .avatar,
.react-select__option .avatar {
  margin: 0;
  padding: 0;
  border-radius: 0%;
  float: unset;
  box-shadow: none;
  margin-right: 3px;
}
.react-select__multi-value .avatar {
  margin: 2px;
}
.react-select__option .avatar {
  margin-left: -5px;
}
.menu-icon {
  font-size: 1.4em;
  margin-right: 10px;
  margin-top: -3px;
  float: left;
}
.sublink .menu-icon {
  margin-top: 0px;
}
.page-title {
  color: white;
  font-size: 1.7em;
  float: left;
}
.sub-heading {
  color: #3cb679;
  font-size: 0.9em;
  position: absolute;
  left: 0;
  top: 28px;
}
.page-title.hex-img {
  margin-left: 160px !important;
}
.sub-heading.hex-img {
  left: 15px !important;
}
#dataForm .hex-img {
  margin-left: 160px !important;
}
.page-title.no-image {
  margin-left: 26px;
}
.sub-heading.no-image {
  left: 35px;
}
#tile-container {
  overflow: hidden;
  max-height: 600px;
}
.gaas-tile {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 20px auto;
  width: 300px;
  min-height: 125px;
  position: relative;
}
@media screen and (max-width: 1280px) {
  .gaas-tile {
    width: 250px;
  }
  .sub-heading {
    top: 23px;
  }
}
.gaas-tile.fill {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 10px 0;
  width: 100% !important;
  min-height: 0px;
  position: relative;
}
.gaas-tile-image {
  position: absolute;
  top: -20px;
  left: -22px;
}
.gaas-tile-image img {
  border: 1px solid lightgray;
}
.image-wrapper {
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
}
.gaas-tile-heading {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px 15px;
  font-size: 1.3em;
  margin-left: 0;
  color: #297c53;
}
@media screen and (max-width: 1280px) {
  .gaas-tile-heading {
    padding: 5px;
    padding-left: 15px;
  }
}
.gaas-tile-heading.h-img {
  margin-left: 54px;
}
.gaas-tile-body {
  padding: 10px;
  color: white;
  min-height: 100px;
  font-size: 1.2em;
}
@media screen and (max-width: 1280px) {
  .gaas-tile-heading.h-img {
    margin-left: 30px;
  }
  .gaas-tile-body {
    padding: 5px;
  }
  .gaas-tile-body .glyphicon {
    display: none;
  }
}
.gaas-tile-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1.3em;
}
.gaas-tile-btn {
  padding: 5px 25px !important;
  background-color: #3db77a;
  cursor: pointer;
  border: none;
  outline: none;
}
.gaas-tile-btn:hover {
  background-color: #297c53;
}
.gaas-table-link {
  color: #3cb679;
  cursor: pointer;
}
.gaas-delete-p {
  background-color: #607d8b;
  border-radius: 2px;
  padding: 3px 5px;
}
#mask {
  position: absolute;
  width: 180px;
  height: 180px;
  top: 1%;
  left: 1%;
  background-color: white;
  -webkit-mask-box-image: url(./images/image-mask.png);
  background-size: contain;
  background-repeat: no-repeat !important;
  background-position: calc(110%) calc(39%) !important;
}
#maskborder {
  position: absolute;
  left: -16px;
  top: -39px;
  width: 185px;
  height: 185px;
  background-color: white;
  -webkit-mask-box-image: url(./images/image-mask.png);
  background-size: contain;
}
.achievement-panel #maskborder {
  left: -77px;
  top: 493px;
}
.badge-panel #maskborder {
  left: -77px;
  top: 826px;
}
.hexagon {
  position: absolute;
  top: -25%;
  left: -90px;
  display: inline-block;
  width: 205px;
  height: 233px;
  overflow: hidden;
  color: #fff;
  mask-image: url("http://i40.tinypic.com/kajqg.jpg");
  -webkit-mask-image: url("http://i40.tinypic.com/kajqg.jpg");
  text-align: center;
}
.hexagon .content {
  height: 186px;
  width: 186px;
  margin: auto;
  margin-top: 16px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.02);
}

::-webkit-scrollbar-track:active {
  background: rgba(255, 255, 255, 0.2);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3cb679;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #297c53;
}

#scroller {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
  position: relative;
  scrollbar-color: #3cb679 rgba(255, 255, 255, 0.02);
  scrollbar-width: thin;
  /* scrollbar-base-color: black; */
  scrollbar-face-color: #3cb679;
  scrollbar-track-color: #030d19;
  scrollbar-arrow-color: #3cb679;
}

#scroller.panel-body, .dataFormList {
  height: 75vh;
  /* overflow: visible; */
}

#dataForm .dataFormList #scroller {
  height: unset !important;
}

@media all and (max-height: 300px) {
  #scroller.panel-body {
    height: 40vh;
  }
}

#content-loader {
  margin-top: 30px;
  margin-left: 150px;
  margin-right: 150px;
  padding-right: 10px;
}
#content-loader.wide {
  margin-left: 50px;
  margin-right: 50px;
}
@media all and (max-width: 1440px) {
  #content-loader {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-right: 10px;
  }
}
.list-avatar {
  float: left;
  height: 30px;
  width: 30px;
  /*margin-top: 13px;*/
  margin-right: 1em;
  margin-left: 10px;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
}

.line {
  *width: 100%;
  height: 2px;
  margin: 10px 0;
  font-size: 0;
  overflow: hidden;
  background-color: transparent;
  border-width: 0;
  border-top: 2px solid rgba(255, 255, 255, 0.3) !important;
}
.line-xs {
  margin: 0;
}
.line-lg {
  margin-top: 15px;
  margin-bottom: 15px;
}
.line-dashed {
  border-style: dashed;
  background: transparent;
}
.no-line {
  border-width: 0;
}
.no-border,
.no-borders {
  border-color: transparent !important;
  border-width: 0;
}
.no-radius {
  border-radius: 0;
}
.block {
  display: block;
}
.block.hide {
  display: none;
}
.inline {
  display: inline-block;
}
.pull-right-lg {
  float: right;
}
.none {
  display: none;
}
.pull-none {
  float: none;
}
.form-control-trans {
  border: none;
  background: transparent;
}
.bg-white-only {
  background-color: #fff;
}
.rounded {
  border-radius: 500px;
}
.btn-s-xs {
  min-width: 90px;
}
.btn-s-sm {
  min-width: 100px;
}
.btn-s-md {
  min-width: 120px;
}
.btn-s-lg {
  min-width: 150px;
}
.btn-s-xl {
  min-width: 200px;
}
.l-h-2x {
  line-height: 2em;
}
.l-h-1x {
  line-height: 1.2;
}
.v-middle {
  vertical-align: middle !important;
}
.v-top {
  vertical-align: top;
}
.v-bottom {
  vertical-align: bottom;
}
.font-thin {
  font-weight: 300;
}
.font-normal {
  font-weight: normal;
}
.font-semibold {
  font-weight: 400;
}
.font-bold {
  font-weight: 600;
}
.text-underline {
  /*text-decoration: underline;*/
  line-height: 35px;
}
@media screen and (max-width: 1280px) {
  .text-underline {
    line-height: 25px;
  }
}
.text-ellipsis {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.block-with-text {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  height: 3.6em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}
.block-with-text:hover {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  /*overflow: visible;*/
  white-space: normal;
  width: auto;
  height: 6.6em !important;
  color: black;
  position: absolute;
  background-color: #fff;
  z-index: 999999;
}
.block-with-text:before {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 0;
}
.block-with-text:after {
  content: "";
  position: absolute;
  right: 0;
  width: 0em;
  height: 0em;
  margin-top: 0.2em;
  background: white;
}
.text-uc {
  text-transform: uppercase;
}
.wrapper {
  padding: 15px;
}
.wrapper-lg {
  padding: 30px;
}
.wrapper-xl {
  padding: 50px;
}
.padder {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
}
.padder-v {
  padding-top: 15px;
  padding-bottom: 15px;
}
.no-padder {
  padding: 0 !important;
}
.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}
.pull-out {
  margin: -10px -15px;
}
.b-t {
  border-top: 1px solid #e0e4e8;
}
.b-r {
  border-right: 1px solid #e0e4e8;
}
.b-b {
  border-bottom: 1px solid #e0e4e8;
}
.b-l {
  border-left: 1px solid #e0e4e8;
}
.pos-rel {
  position: relative;
}
.pos-tr {
  position: absolute;
  top: 0;
  right: 0;
}
.pos-tr-sm {
  position: absolute;
  top: 0;
  right: 0;
  top: 5px;
  right: 5px;
}
.m-n {
  margin: 0 !important;
}
/*margin xxsm*/
.m-xxsm {
  margin: 3px !important;
}
.m-l-xxsm {
  margin-left: 3px !important;
}
.m-r-xxsm {
  margin-right: 3px !important;
}
.m-t-xxsm {
  margin-top: 3px !important;
}
.m-b-xxsm {
  margin-bottom: 3px !important;
}
/*margin xsm*/
.m-xsm {
  margin: 5px !important;
}
.m-l-xsm {
  margin-left: 5px !important;
}
.m-r-xsm {
  margin-right: 5px !important;
}
.m-t-xsm {
  margin-top: 5px !important;
}
.m-b-xsm {
  margin-bottom: 5px !important;
}
/*margin sm*/
.m-sm {
  margin: 10px !important;
}
.m-l-sm {
  margin-left: 10px !important;
}
.m-r-sm {
  margin-right: 10px !important;
}
.m-t-sm {
  margin-top: 10px !important;
}
.m-b-sm {
  margin-bottom: 10px !important;
}
/*margin norm*/
.m {
  margin: 15px !important;
}
.m-l {
  margin-left: 15px !important;
}
.m-r {
  margin-right: 15px !important;
}
.m-t {
  margin-top: 15px !important;
}
.m-b {
  margin-bottom: 15px !important;
}
/*margin md*/
.m-md {
  margin: 20px !important;
}
.m-l-md {
  margin-left: 20px !important;
}
.m-r-md {
  margin-right: 20px !important;
}
.m-t-md {
  margin-top: 20px !important;
}
.m-b-md {
  margin-bottom: 20px !important;
}
/*margin lg*/
.m-lg {
  margin: 25px !important;
}
.m-l-lg {
  margin-left: 25px !important;
}
.m-r-lg {
  margin-right: 25px !important;
}
.m-t-lg {
  margin-top: 25px !important;
}
.m-b-lg {
  margin-bottom: 25px !important;
}
/*margin xlg*/
.m-xlg {
  margin: 30px !important;
}
.m-l-xlg {
  margin-left: 30px !important;
}
.m-r-xlg {
  margin-right: 30px !important;
}
.m-t-xlg {
  margin-top: 30px !important;
}
.m-b-xlg {
  margin-bottom: 30px !important;
}
/*margin xxlg*/
.m-xxlg {
  margin: 45px !important;
}
.m-l-xxlg {
  margin-left: 45px !important;
}
.m-r-xxlg {
  margin-right: 45px !important;
}
.m-t-xxlg {
  margin-top: 45px !important;
}
.m-b-xxlg {
  margin-bottom: 45px !important;
}
.p-n {
  padding: 0 !important;
}
/*padding xxsm*/
.p-xxsm {
  padding: 3px !important;
}
.p-l-xxsm {
  padding-left: 3px !important;
}
.p-r-xxsm {
  padding-right: 3px !important;
}
.p-t-xxsm {
  padding-top: 3px !important;
}
.p-b-xxsm {
  padding-bottom: 3px !important;
}
/*padding xsm*/
.p-xsm {
  padding: 5px !important;
}
.p-l-xsm {
  padding-left: 5px !important;
}
.p-r-xsm {
  padding-right: 5px !important;
}
.p-t-xsm {
  padding-top: 5px !important;
}
.p-b-xsm {
  padding-bottom: 5px !important;
}
/*padding sm*/
.p-sm {
  padding: 10px !important;
}
.p-l-sm {
  padding-left: 10px !important;
}
.p-r-sm {
  padding-right: 10px !important;
}
.p-t-sm {
  padding-top: 10px !important;
}
.p-b-sm {
  padding-bottom: 10px !important;
}
/*padding norm*/
.p {
  padding: 15px !important;
}
.p-l {
  padding-left: 15px !important;
}
.p-r {
  padding-right: 15px !important;
}
.p-t {
  padding-top: 15px !important;
}
.p-b {
  padding-bottom: 15px !important;
}
/*padding md*/
.p-md {
  padding: 20px !important;
}
.p-l-md {
  padding-left: 20px !important;
}
.p-r-md {
  padding-right: 20px !important;
}
.p-t-md {
  padding-top: 20px !important;
}
.p-b-md {
  padding-bottom: 20px !important;
}
/*padding lg*/
.p-lg {
  padding: 25px !important;
}
.p-l-lg {
  padding-left: 25px !important;
}
.p-r-lg {
  padding-right: 25px !important;
}
.p-t-lg {
  padding-top: 25px !important;
}
.p-b-lg {
  padding-bottom: 25px !important;
}
/*padding xlg*/
.p-xlg {
  padding: 30px !important;
}
.p-l-xlg {
  padding-left: 30px !important;
}
.p-r-xlg {
  padding-right: 30px !important;
}
.p-t-xlg {
  padding-top: 30px !important;
}
.p-b-xlg {
  padding-bottom: 30px !important;
}
/*padding xxlg*/
.p-xxlg {
  padding: 45px !important;
}
.p-l-xxlg {
  padding-left: 45px !important;
}
.p-r-xxlg {
  padding-right: 45px !important;
}
.p-t-xxlg {
  padding-top: 45px !important;
}
.p-b-xxlg {
  padding-bottom: 45px !important;
}
.media-xs {
  min-width: 50px;
}
.media-sm {
  min-width: 80px;
}
.media-md {
  min-width: 90px;
}
.media-lg {
  min-width: 120px;
}
.thumb {
  width: 64px;
  display: inline-block;
}
.thumb-lg {
  width: 128px;
  display: inline-block;
}
.thumb-md {
  width: 90px;
  display: inline-block;
}
.thumb-xs {
  width: 18px;
  display: inline-block;
}
.thumb-sm {
  width: 36px;
  display: inline-block;
}
.thumb img,
.thumb-xs img,
.thumb-sm img,
.thumb-md img,
.thumb-lg img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
.img-full {
  max-width: 100%;
}
.img-full > img {
  max-width: 100%;
}
.clear {
  display: block;
  overflow: hidden;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #ddd;
}
@media screen and (min-width: 1271px) and (max-width: 1440px) {
  .m-r-sm {
    margin-right: 10px !important;
    padding-top: 10px;
    margin-left: 5px;
  }
}

.pagination > li > a,
.pagination > li > a:link,
.pagination > li > a:hover,
.pagination > li > a:active,
.pagination > li > a:focus,
.pagination > li > a:visited {
  background-color: transparent !important;
  color: white !important;
  border-color: transparent !important;
  outline: 0;
}

.pagination
  > li:not(.active):not(.next):not(.previous):not(.disabled)
  > a:hover {
  border-color: #3db77a !important;
}

.pagination > .active > a,
.pagination > .active > a:link,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > a:active,
.pagination > .active > a:visited {
  background-color: #3db77a !important;
  border-color: #3db77a !important;
}

.pagination > .disabled > a,
.pagination > .disabled > a:hover {
  color: gray !important;
  cursor: default !important;
}

.pageStyle {
  font-size: 1.7em;
  float: right;
}

button > .hover-text,
.floating-button .hover-text {
  display: none;
  position: absolute;
  font-size: 0.5em;
  text-transform: uppercase;
  line-height: 10px;
  white-space: nowrap;
}

button:hover > .hover-text,
.floating-button:hover .hover-text {
  display: block;
}

.pageStyle > button,
.glyphbutton {
  background: transparent;
  border: none;
  outline: none;
  padding: 1px 6px;
}

.glyphbutton:disabled{
  opacity: 0;
}

.pageStyle > .active {
  color: #3db77a;
}

.pageStyle > .inactive {
  opacity: 0.5;
}

.pageStyle > .inactive:hover {
  opacity: 1;
  cursor: pointer;
}

.list .sort {
  float: right;
}

.list .sort > * {
  display: inline-block;
  margin: 6px 5px;
  padding: 2px 4px;
}

.list .sort > button > i {
  margin-right: 5px;
}

.list .sort > button.active {
  background-color: #3cb679;
}

@media screen and (max-width: 1440px) {
  .pageStyle > button {
    padding: 1px 3px;
  }
  .list>div:first-child, #dataForm>div:first-child {
    padding-right: 30px;
  }
  .list .sort > * {
    margin: 3px 2px;
    padding: 1px 2px;
  }
}

.ReactTable {
  background-color: rgba(255, 255, 255, 0.2);
}

.ReactTable .rt-thead {
  background-color: rgba(255, 255, 255, 0.8);
  color: #297c53;
  font-size: 1.3em;
}

.ReactTable .rt-th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.ReactTable .rt-tbody {
  font-size: 1.2em;
  overflow: hidden !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc {
  -webkit-box-shadow: inset 0 3px 0 0 #3db77a !important;
  box-shadow: inset 0 3px 0 0 #3db77a !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
  -webkit-box-shadow: inset 0 -3px 0 0 #3db77a !important;
  box-shadow: inset 0 -3px 0 0 #3db77a !important;
}

.ReactTable .rt-thead .rt-th {
  outline: none;
  text-align: left;
}

.ReactTable .-even {
  background-color: rgba(255, 255, 255, 0.05);
}

.ReactTable .gaas-tile-btn {
  display: inline-block;
  margin-top: -2px;
}

.ReactTable .image-wrapper {
  display: inline-block;
  margin-top: -2px;
  margin-left: -3px;
}

.panel-heading {
  background-image: none !important;
  background-color: rgba(61, 183, 122, 0.9) !important;
  margin-top: -1px;
  padding: 2px 5px;
}

.panel-menu {
  position: absolute;
  top: 150px;
  left: 0px;
}

.image-menu {
  top: 0px;
  left: -50px;
}

.panel-menu > div {
  background-color: rgba(255, 255, 255, 0.3);
  margin: 5px 0;
  font-size: 1.1em;
}

.panel-menu button {
  padding: 10px 28px;
  width: 72px;
}

.image-menu button {
  padding: 5px 10px;
  width: 40px;
}

.panel-menu > div.active {
  background-color: #3cb679;
}

.panel {
  color: #f5f5f5;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
  overflow-y: auto;
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 50px !important;
}

.panel.hex {
  margin-left: 62px !important;
}

.panel-body.hex {
  overflow-y: auto;
  padding-top: 70px !important;
  padding-left: 80px;
  padding-right: 40px !important;
}

@media screen and (max-width: 1280px) {
  .panel-body.hex {
    padding-left: 40px;
    padding-right: 10px !important;
    padding-top: 20px !important;
  }
}

.form-group {
  margin-bottom: 15px;
}

.form-group.form-group-lg label.control-label {
  font-size: 1em;
  line-height: 0.99999998;
  color: #bdbdbd;
  font-weight: 400;
  margin: 16px 0 0 0;
  width: 100%;
}

@media screen and (max-height: 800px) {
  .form-group {
    margin-bottom: 5px;
  }
  .form-group.form-group-lg label.control-label {
    margin: 5px 0 0 0;
  }
}

label.control-label>span {
  font-size: 0.7em;
  margin-left: 5px;
}

.form-control,
.form-group .form-control {
  border: 0;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#3db77a),
      to(#3db77a)
    ),
    -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
  background-image: -webkit-linear-gradient(#3db77a, #3db77a),
    -webkit-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: -o-linear-gradient(#3db77a, #3db77a),
    -o-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: linear-gradient(#3db77a, #3db77a),
    linear-gradient(#d2d2d2, #d2d2d2);
  -webkit-background-size: 0 2px, 100% 1px;
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center -webkit-calc(100% - 1px);
  background-position: center bottom, center calc(100% - 1px);
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: background 0s ease-out;
  -o-transition: background 0s ease-out;
  transition: background 0s ease-out;
  float: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.form-control.error,
.form-control.warning {
  outline: none;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#c65353),
      to(#c65353)
    ),
    -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
  background-image: -webkit-linear-gradient(#c65353, #c65353),
    -webkit-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: -o-linear-gradient(#c65353, #c65353),
    -o-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: linear-gradient(#c65353, #c65353),
    linear-gradient(#d2d2d2, #d2d2d2);
  -webkit-background-size: 100% 2px, 100% 1px;
  background-size: 100% 2px, 100% 1px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.form-control.warning {
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ff9c4b),
      to(#ff9c4b)
    ),
    -webkit-gradient(linear, left top, left bottom, from(#d2d2d2), to(#d2d2d2));
  background-image: -webkit-linear-gradient(#ff9c4b, #ff9c4b),
    -webkit-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: -o-linear-gradient(#ff9c4b, #ff9c4b),
    -o-linear-gradient(#d2d2d2, #d2d2d2);
  background-image: linear-gradient(#ff9c4b, #ff9c4b),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.form-control {
  color: #fff !important;
}

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 1.1em;
  line-height: 1.8;
  border-radius: 6px;
  padding-left: 0;
}

@media screen and (max-height: 800px) {
  .form-group-lg .form-control {
    height: 36px;
    padding: 5px 16px;
    padding-left: 0;
  }
}

.btn-error {
  border: 1px solid #ff4d40 !important;
}

.attribute .form-group-lg .form-control {
  font-size: 0.9em;
  padding-right: 0;
}

.attribute .react-select__clear-indicator {
  padding-left: 4px;
  padding-right: 4px;
}

.attribute .react-select__dropdown-indicator {
  padding-left: 4px;
  padding-right: 4px;
}

.attribute > div {
  padding: 0 5px;
}

.attribute .and-label {
  position: absolute;
  margin-left: -60px;
  color: #b7b7b7;
}

.floating-button {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #3cb679;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 1.3em;
  cursor: pointer;
}

.floating-button:hover {
  background-color: #297c53;
}

.floating-button > * {
  margin-top: 20px;
}

@media screen and (max-height: 800px) {
  .floating-button {
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
  }
  .floating-button > * {
    margin-top: 12px;
  }
}

#pager {
  position: fixed;
  bottom: 40px;
  left: 50%;
}

.dataFormList {
  overflow-x: hidden;
}

.dataFormList #pager {
  bottom: 60px;
  left: 60%;
}

#pager > .pagination {
  margin: 0;
}

#pager > ul {
  margin: 0;
}

@media screen and (max-height: 800px) {
  #pager {
    bottom: 10px;
  }
  .dataFormList #pager {
    bottom: 40px;
  }
  #pager > .pagination {
    margin: 0px;
  }
}

.table-with-header {
  padding: 5px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  border-width: 40px 20px 20px 20px;
}

.table-with-header > header {
  margin-top: -35px;
  margin-bottom: 20px;
  font-size: 1.4em;
}

.notification-toast.Toastify__toast--success {
  background-color: #3cb679;
}

@media screen and (max-height: 800px) {
  .Toastify__toast {
    min-height: 48px !important;
    width: 240px;
  }
}

select option {
  background: #172f3b;
  color: #fff;
}

.form-additional-info {
  border-left: 1px solid #3cb679;
  padding: 10px;
  min-height: 100%;
}

.form-additional-info label {
  font-size: 1em;
  color: #bdbdbd;
  font-weight: 400;
  margin-top: 10px;
  display: block;
}

.form-additional-info label > * {
  font-size: 0.8em;
}

.rc-switch-checked {
  border: 1px solid #3cb679 !important;
  background-color: #3cb679 !important;
}

.change-record {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin: 20px 10px;
}

.change-record-header {
  text-align: right;
  display: block;
}

.change-record-body {
  font-size: 0.9em;
  font-style: italic;
}

.change-notes-seperator {
  border-top: #3cb679 1px solid;
  margin: 5px 0;
}

.change-record-body,
.notes-record-body {
  text-align: left;
  white-space: pre-line;
}

.react-select-container {
  padding: 6px 0 !important;
}

.react-select__control {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.react-select__placeholder {
  color: #989388 !important;
}

.react-select__indicator {
  color: #d2d2d2 !important;
}

.react-select__menu {
  color: white !important;
  border-radius: 0 !important;
  background-color: #b7b7b7 !important;
}

.react-select__option--is-focused {
  background-color: #3cb679 !important;
}

.react-select__option--is-selected {
  background-color: #297c53 !important;
}

.react-select__single-value {
  color: #fff !important;
}

.react-select__value-container {
  padding-left: 0px !important;
}

.react-select__placeholder {
  color: white !important;
}

.filter .react-select__placeholder,
.filter .react-select__single-value {
  color: #3cb679 !important;
}

.filter .react-select-container {
  padding: 0 !important;
  font-size: 0.9em;
  height: 28px;
  line-height: 14px !important;
  margin-top: -10px;
}

.filter .react-select__indicator-separator {
  width: 0 !important;
}

@media screen and (max-height: 800px) {
  .react-select__indicator-separator {
    margin-bottom: 12px !important;
    margin-top: 12px !important;
  }
  .react-select__multi-value__label {
    padding: 1px !important;
  }
  .react-select__multi-value {
    margin-top: -5px !important;
  }
  .react-select__option {
    padding: 3px 2px !important;
  }
  .react-select__option .avatar {
    margin-left: -1px;
  }
}

.task-notes {
  background-color: rgba(182, 191, 195, 0.2) !important;
}

.gaas-tile-body .row > div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.priority-cell {
  height: 100%;
  width: 15px;
}

.priority-cell-1 {
  background-color: yellow;
}

.priority-cell-2 {
  background-color: green;
}

.priority-cell-3 {
  background-color: orange;
}

.priority-cell-4 {
  background-color: red;
}

.avatar-priority-1 {
  -webkit-box-shadow: 0 0 0 3px yellow, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0 0 0 3px yellow, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 3px yellow, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
}

.avatar-priority-2 {
  -webkit-box-shadow: 0 0 0 3px green, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0 0 0 3px green, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 3px green, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
}

.avatar-priority-3 {
  -webkit-box-shadow: 0 0 0 3px orange, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0 0 0 3px orange, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 3px orange, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
}

.avatar-priority-4 {
  -webkit-box-shadow: 0 0 0 3px red, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0 0 0 3px red, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 3px red, 0 0 0 4px #999,
    0 2px 5px 4px rgba(255, 255, 255, 0.2);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  padding-left: 8px !important;
}

.react-datepicker__time-list {
  padding-inline-start: 0;
}

.react-datepicker__close-icon::after {
  background-color: transparent !important;
  color: #555 !important;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
  background-color: rgba(101, 203, 152, 0.7);
  color: white;
  font-weight: 400;
  text-align: center;

  padding: 15px 50px 5px 50px;
  margin-top: 0;
  margin-left: -150px;

  width: 150px;
  border-bottom: 3px white solid;

  position: absolute;
}

.custom-file-upload:hover {
  background-color: #3cb679;
}

@media screen and (max-width: 1280px) {
  .custom-file-upload {
    margin-left: -120px;
    width: 120px;
    }
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
}

.card {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0;
  padding: 1.5rem 1.5rem;
  position: relative;
}

.delete-btn {
  color: #f44336;
}

.confirm-dialog-content {
  border: none !important;
  width: 90% !important;
  max-width: 500px !important;
  padding: 0px !important;
}

.confirm-container {
  background-color: #efefef !important;
  border-radius: 0.25em 0.25em 0 0;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.confirm-container p {
  padding: 3em 1em;
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
}

.confirm-buttons button {
  float: left;
  width: 50%;
  height: 60px;
  line-height: 60px;
  text-transform: uppercase;
  color: white;
  outline: none;
  border: none;
}

.confirm-buttons button:first-child {
  background: #fc7169;
}

.confirm-buttons button:last-child {
  background: #3cb679;
}

.confirm-buttons button:first-child:hover {
  background: #b75751;
}

.confirm-buttons button:last-child:hover {
  background: #297c53;
}

.form-subsection > button,
.row.attribute button {
  position: absolute;
  margin-left: -55px;
  margin-top: -25px;
}

.row.attribute button {
  margin-top: 40px;
  margin-left: -40px;
  left: 0;
}

.form-subsection .gaas-tile-body {
  font-size: 1em;
}

.form-subsection .gaas-tile-heading,
.form-subsection .gaas-tile-footer {
  font-size: 1.1em;
}

.form-subsection .gaas-tile {
  width: 240px;
}

.form-subsection .text-underline {
  line-height: 25px;
}

.form-subsection .padder {
  padding-left: 5px;
  padding-right: 5px;
}

.clear-file {
  bottom: 0;
  left: 0;
  text-align: center;
  position: absolute;
  margin-bottom: 10px;
  opacity: 0.5;
  padding: 0;
  margin: 0 0 10px 5px;
}

.clear-file:hover {
  opacity: 1;
}

.clear-file span {
  /* width: 100%; */
  border-radius: 0;
}

.back-button {
  color: rgb(255, 252, 85);
}

.image-loading {
  -webkit-animation: spin 0.4s linear 1 0.5s;
  -moz-animation: spin 0.4s linear 1 0.5s;
  animation: spin 0.4s linear 1 0.5s;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.log-details {
  margin: 20px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  word-break: break-word;
}

.log-details .col-md-3 {
  text-align: right;
}

.scope-title {
  position: fixed;
  left: 20px;
  bottom: 10px;
}

.popup-overlay {
  align-items: center; /* horizontal */
  justify-content: center; /* vertical */
}

.popup-content {
  margin: auto 0 !important;
}

.image-drop {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  font-size: 2.4em;
  text-align: center;
  color: grey;
}

.image-drop > * {
  top: 50px;
}

.search-button {
  position: absolute;
  top: -10px;
  right: -5px;
  padding: 20px;
  color: #3db77a;
  outline: none;
  border: none;
  background: transparent;
  font-size: 1.8em;
  cursor: pointer;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}

.search-button-opened {
  transform-origin: top right;
  transform: scale(1.5) translate(-10px, 5px);
  -ms-transform: scale(1.5) translate(-10px, 5px);
  -webkit-transform: scale(1.5) translate(-10px, 5px);
}

.search-button .hover-text {
  margin-left: -10px;
}

.search-button-opened .hover-text {
  margin-left: -5px;
}

@media screen and (max-width: 1440px) {
  .search-button {
    padding: 10px;
    font-size: 1.5em;
  }
  .search-button-opened {
    padding: 20px;
  }
  .search-button-opened .hover-text {
    margin-left: 0;
  }
}

.search-window {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  background-color: #3db77a;
}

.search-contents {
  height: 100%;
  background-color: darkslategray;
  padding: 50px;
}

.search-contents > input {
  font-size: 5vw;
  line-height: 1;
  display: block;
  width: 60%;
  padding: 0.05em 0;
  color: white;
  border: 0;
  border-bottom: 2px solid #3db77a;
  background: transparent;
  outline: none;
  margin: 0 20%;
}

.search-contents > span {
  font-size: 0.9em;
  font-weight: bold;
  display: block;
  width: 60%;
  margin: 0 20%;
  padding: 0.85em 0;
  text-align: right;
  color: #3db77a;
}

#dataForm .filters  {
  background-color:rgba(182, 191, 195, 0.1);
  margin-bottom: 5px;
}

/* date select */
/* ::-webkit-datetime-edit-text,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-year-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-ampm-field {
  color: rgba(255, 255, 255, 0.2);
}
::-webkit-datetime-edit-text:focus,
::-webkit-datetime-edit-month-field:focus,
::-webkit-datetime-edit-day-field:focus,
::-webkit-datetime-edit-year-field:focus,
::-webkit-datetime-edit-hour-field:focus,
::-webkit-datetime-edit-minute-field:focus,
::-webkit-datetime-edit-ampm-field:focus {
  color: black;
} */
::-webkit-inner-spin-button { display: none; }
::-webkit-calendar-picker-indicator { filter: invert(1); }

.profile-selector{
  position: relative;
  z-index: 1;
}

.game-editor .profile-selector{
  margin-left: 80px;
}

.profile-selector>div{
  display: inline-block;
  border: rgba(255, 255, 255, 0.4) dashed 1px;
  margin: 5px 10px 5px 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0.5;
}

.profile-selector>div:hover{
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.profile-selector>div.active {
  background-color: rgba(61, 183, 122, 0.6);
  opacity: 1;
}

.profile-selector .btn-profile{
  outline: none;
  border: none;
  padding: 5px 10px;
  background-color: transparent;
}

.profile-selector .btn-profile-remove{
  margin-left: 0px;
  margin-right: 3px;
  color: #f44336;
  opacity: 0.6;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
}

.profile-selector .btn-profile-remove:hover {
  opacity: 1;
}

.profile-selector img {
  height: 24px;
  margin: -5px 5px -3px -5px;
}

.profile-selector > i {
  color: rgba(0, 0, 0, 0.3);
  margin-right: 2px;
  margin-left: -7px;
}

.refresh-button {
  color: #3db77a;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: all 0.1s linear;
}

.refresh-button > i {
  font-size: 1.2em;
}

.refresh-button:hover {
  transform: scale(1.5);
}