.game {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 20px 30px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid white;
  width: 60%;
  border: none;
  outline: none;
  position: relative;
  margin-bottom: -1px;
}

.game.started,
.rule.started {
  background-color: rgba(203, 201, 101, 0.5);
}

.game.violated,
.rule.violated {
  background-color: rgba(203, 101, 101, 0.5);
}

.game.completed,
.rule.completed {
  background-color: rgba(101, 203, 152, 0.5);
}

.game:hover:not(.completed):not(.violated):not(.started) {
  background-color: rgba(0, 0, 0, 0.3);
}

.game-title {
  font-size: 1.2em;
}

.union-title {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 12px;
  position: relative;
  text-align: center;
  font-size: 1.1em;
}

.union-advanced-mode .union-title {
  padding: 2px 0;
  line-height: 20px;
  text-align: left;
  padding-left: 50px;
}

.union-advanced-mode .gameplay-switch {
  padding: 0;
}

.union-advanced-mode {
  border: 1px dotted darkgray;
  padding-bottom: 10px;
}

.rule img,
.participant img,
.reward img,
.filter img,
.ingame img {
  height: 24px;
  margin-right: 5px;
  margin-left: 5px;
}

.reward-new,
.participant-new,
.filter-new,
.rule-new,
.group-new,
.ingame-new {
  text-decoration: underline;
  font-size: 0.9em;
  background-color: transparent;
  outline: none;
  border: none;
}

.reward-new:hover,
.participant-new:hover,
.rule-new:hover,
.filter-new:hover,
.group-new:hover,
.ingame-new:hover {
  color: #3db77a;
}

.reward > .reward-value {
  width: 50px;
  line-height: 16px;
  text-decoration: underline;
  float: right;
  margin-right: 20px;
  background-color: transparent;
  outline: none;
  border: none;
  margin-top: 5px;
}

.reward > span.reward-value {
  text-decoration: none;
}

@media screen and (max-width: 1280px) {
  .reward > .reward-value {
    margin-right: 0;
  }
}

.participant,
.filter,
.rule,
.reward,
.ingame {
  padding: 5px 15px 5px 0;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  outline: none;
  border: none;
  text-align: left;
}

.participant:hover:not(.completed):not(.violated):not(.started),
.filter:hover:not(.completed):not(.violated):not(.started),
.rule:hover:not(.completed):not(.violated):not(.started),
.reward:hover:not(.completed):not(.violated):not(.started)
  .ingame:hover:not(.completed):not(.violated):not(.started) {
  background-color: rgba(0, 0, 0, 0.2);
}

.reward.reward-fixed {
  white-space: inherit;
  overflow: inherit;
  text-overflow: inherit;
  cursor: default;
}

.game-title > i,
.union-title > i,
.union-title > button > i {
  color: #3db77a;
  margin-right: 5px;
}

.game.error,
.union-title.error {
  border-bottom: 1px solid #f44336;
}

.union-title.valid {
  border-bottom: 1px solid #3db77a;
}

.game-title > .error,
.union-title > .error {
  color: #ff4d40;
  font-size: 0.8em;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 12px;
}

.game.warning,
.union-title.warning {
  border-bottom: 1px solid #fadd38;
}

.union-title > .warning {
  color: #fadd38;
  font-size: 0.8em;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 12px;
}

.popup-content {
  color: #f5f5f5;
}

.game-editor-overlay .popup-content {
  width: 40% !important;
}

.reward-content {
  width: 236px !important;
  border: 2px solid #f5f5f5;
}

.reward-rank {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 0.7em; 
  line-height: 1em;
}

.reward-rank-content {
  border: 2px solid #f5f5f5;
  padding: 10px; 
  background-color: #414952;
}

.reward-rank-content input {
  margin: 5px;
  background-color: transparent;
}

.conjunction-container-content {
  width: auto !important;
  border: 2px solid #f5f5f5;
}

.btn-remove, .btn-notification {
  position: absolute;
  top: 0;
  right: 0;
  color: #f44336;
  margin: 10px 0;
  line-height: 14px;
  font-size: 1em;
  opacity: 0.6;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 10px 2px;
}

.btn-notification {
  color: #fadd38;
  margin-right: 20px;
}

.btn-notification.active {
  color: #3db77a;
}

.btn-notification > span {
  font-size: 10px;
  position: absolute;
  margin-top: -7px;
  margin-left: -2px;
}

.union-advanced-mode > .btn-remove {
  top: 5px;
  right: -17px;
}

.btn-remove:hover, .btn-remove:disabled, .btn-notification:hover {
  opacity: 1;
}

.btn-remove:disabled {
  color: #8d8d8d;
}

.no-tree {
  padding: 0;
  list-style: none;
}

.tree {
  margin: 0 0 0 8px; /* indentation */
  padding: 0;
  list-style: none;
  position: relative;
}

.subtree,
.subtree.subgroup {
  margin-left: 40px;
}

.tree:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid;
}

.tree li {
  margin: 0;
  padding: 8px 0 0px 10px;
  line-height: 30px; /* default list item's `line-height` */
  position: relative;
}

.subtree li {
  line-height: 20px;
}

.tree li:before {
  content: "";
  display: block;
  width: 10px;
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  position: absolute;
  top: 30px; /* (line-height/2) */
  left: 0;
}

.subtree li:before {
  top: 20px;
}

.tree li:last-child:before {
  background: transparent;
  height: auto;
  bottom: 0;
}

.game-switch {
  font-weight: unset;
  float: right;
}

.game-settings {
  background-color: transparent;
}

.game-element-selector,
.currencies,
.experiences,
.conjunctions,
.notifications-content {
  padding: 20px; 
  background-color: #414952;
}

.conjunctions button {
  text-transform: lowercase;
}

.currencies,
.experiences,
.conjunctions {
  padding: 10px;  
}

.notifications-content {
  padding: 0 0 20px 0;
  min-width: 300px;
}

.game-element-selector #scroller > div > button,
.currencies button,
.experiences button,
.conjunctions button {
  background-color: transparent;
  outline: none;
  width: 100%;
  text-align: left;
  border: 1px solid lightgray;
  margin: 1px 0;
  padding: 0;
}

.experiences button,
.currencies button,
.conjunctions button {
  margin: 0 1px;
  padding: 0 1px;
  width: 40px;
  text-align: center;
}

.experiences button:hover,
.currencies button:hover,
.conjunctions button:hover {
  color: #3db77a;
}

@media screen and (max-width: 1280px) {
  .currencies,
  .experiences,
  .currencies button,
  .experiences button
  {
    line-height: 20px;
  }
}

.game-element-selector #scroller button > img {
  margin-right: 5px;
}

.game-element-selector #scroller > div > button:hover {
  border: 1px solid gray;
  background: gray;
}

.game-element-selector #scroller {
  height: 55vh;
}

.game-element-selector #searchBox {
  float: right;
  color: white;
}

.game-element-selector #scroller .currency,
.game-element-selector #scroller .experience {
  background-color: #4a4f56;
}

.game-element-selector #scroller > div > button.selected {
  background-color: #3db77a;
}

#dataForm {
  position: relative;
}

.game-element-selector #scroller button > img {
  width: 40px;
}

.game-element-selector #scroller .btn-copy {
  color: white;
  float: right;
  width: auto;
  border: none;
  background: none;
  top: 10px;
  right: 30px;
  outline: none;
  position: absolute;
}

.game-element-selector #scroller .btn-copy:hover {
  background: none;
  border: none;
}

.game-element-selector {
  border: 1px solid white;
}

.conjunction-indicator {
  position: absolute;
  top: -3px;
  left: 1px;
  text-transform: lowercase;
  font-size: 0.9em;
  background: white;
  color: black;
  line-height: 12px;
  padding: 1px 2px;
  cursor: default;
  z-index: 1;
}

.conjunction-container .conjunction-indicator {
  cursor: pointer;
}

.game-tree > div {
  padding: 0;
}

.active:disabled, .delete-btn:disabled, .back-button:disabled {
  color: gray;
}

.union-advanced-mode .rule-group-rules {
  margin-left: 15px;
  padding-right: 16px;
}

.gameplay-switch {
  padding: 0;
}

.gameplay-switch button {
  width: 50%;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  padding: 3px 8px;
  background: transparent;
  color: white !important;
}

.gameplay-switch button:hover {
  background: rgba(0, 0, 0, 0.1);
}

.gameplay-switch button i {
  margin-right: 2px;
}

.gameplay-switch button.active {
  background: rgba(61, 183, 122, 0.5);
}

.popup-content .actions {
  overflow: hidden;
  margin-top: 5px;
}

.popup-content .btn-close,
.popup-content .btn-new,
.popup-content .btn-select {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  padding: 3px 8px;
  text-transform: uppercase;
}

.popup-content .btn-close:disabled,
.popup-content .btn-new:disabled,
.popup-content .btn-select:disabled {
  background-color: gray;
}

.popup-content .btn-close,
.popup-content .btn-select {
  float: right;
  margin-left: 10px;
}

.popup-content .btn-new,
.popup-content .btn-select {
  background-color: #3db77a;
}

.popup-content .btn-new:hover,
.popup-content .btn-select:hover {
  background-color: #297c53;
}

.popup-content .btn-close {
  background-color: #ff4d40;
}

.popup-content .btn-close:hover {
  background-color: #e72b1d;
}

.game-editor .panel-body.hex {
  padding-top: 40px !important;
}