.st0 {
  display: none;
}

.st1 {
  display: inline;
  fill: rgba(255, 255, 255, 0.2);
  stroke: #000000;
  stroke-opacity: 0.0001;
  stroke-miterlimit: 10;
}

.st2 {
  fill: #ffffff;
  fill-opacity: 0.0001;
  stroke: rgba(255, 255, 255, 0.2);
}

.st3 {
  fill: #3cb878;
}

.st4 {
  fill: none;
  stroke: #3cb878;
  stroke-miterlimit: 10;
}

.st5 {
  fill: #ffffff;
  cursor: pointer;
}

.st6 {
  font-family: "Open Sans";
}

.st7 {
  font-size: 14px;
}
