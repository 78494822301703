.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  background: rgba(182, 191, 195, 0.1);
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: #3cb679;
  opacity: 0.3;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(255, 255, 255, 0.4);
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
}

.dashboard-tile,
.dashboard-tile .tile-content {
  height: 100%;
  width: 100%;
}

.dashboard-tile .tile-content {
  padding-top: 27px;
}

.dashboard-tile {
  box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.dashboard-tile .hide-button {
  outline: none;
  border: none;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 6px;
}

.dashboard-tile .tile-title,
.dashboard-tile .tile-subtitle {
  border-bottom: 1px solid #3cb679;
  padding: 0 10px 7px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-transform: uppercase;
}

.dashboard-tile .tile-subtitle {
  font-size: 0.7em;
  top: 15px;
  border: none;
  padding: none;
  color: #3cb679;
}

.dashboard-total {
  text-align: center;
  height: 100%;
}

.dashboard-top {
  padding: 5px 10px;
}

.chart-tooltip {
  font-size: 0.9em;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.5);
}

.recharts-symbols {
  opacity: 0.5;
}

.dashboard-top > *,
.dashboard-tile .tile-title,
.dashboard-tile .tile-subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-container {
  overflow: auto;
  height: 90vh;
}